import logo from './logo.svg';
import './App.css';

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import axios from "axios";
import AdminDashboard from "./AdminDashboard";
import Login from "./Login";
import Register from "./Register";

const API_URL = "http://localhost:5000";

function App() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (token) {
      axios.get(`${API_URL}/users`, { headers: { Authorization: token } })
        .then(res => {
          const foundUser = res.data.find(u => u.user_id === localStorage.getItem("userId"));
          if (foundUser) setUser(foundUser);
        })
        .catch(err => {
          console.error("Error fetching user data:", err);
          logout();
        });
    }
  }, [token]);

  const login = (token, userId, isAdmin) => {
    localStorage.setItem("token", token);
    localStorage.setItem("userId", userId);
    localStorage.setItem("isAdmin", isAdmin);
    setToken(token);
    setUser({ user_id: userId, is_admin: isAdmin });
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("isAdmin");
    setToken(null);
    setUser(null);
  };

  return (
    <Router>
      <nav>
        <ul>
          {token ? (
            <>
              {user?.is_admin && <li><a href="/admin">Admin Dashboard</a></li>}
              <li><button onClick={logout}>Logout</button></li>
            </>
          ) : (
            <>
              <li><a href="/login">Login</a></li>
              <li><a href="/register">Register</a></li>
            </>
          )}
        </ul>
      </nav>

      <Routes>
        <Route path="/" element={token ? <Navigate to="/admin" /> : <Navigate to="/login" />} />
        <Route path="/login" element={<Login onLogin={login} />} />
        <Route path="/register" element={<Register />} />
        <Route path="/admin" element={token && user?.is_admin ? <AdminDashboard token={token} /> : <Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;

