import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { VRMLoaderPlugin } from "@pixiv/three-vrm";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"; // ✅ Correct Import

function VRMViewer({ vrmUrl }) {
    const canvasRef = useRef(null);
    let scene, camera, renderer, model, animationFrame;

    useEffect(() => {
        if (!vrmUrl) return;

        const init = async () => {
            scene = new THREE.Scene();
            scene.background = new THREE.Color(0x282c34);

            camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
            camera.position.set(0, 1.5, 3);

            renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
            renderer.setSize(200, 200);
            renderer.setPixelRatio(window.devicePixelRatio);
            canvasRef.current.innerHTML = "";
            canvasRef.current.appendChild(renderer.domElement);

            const light = new THREE.DirectionalLight(0xffffff, 1);
            light.position.set(2, 2, 2);
            scene.add(light);

            const loader = new GLTFLoader(); // ✅ Correct GLTFLoader Usage
            loader.register(parser => new VRMLoaderPlugin(parser));

            try {
                const response = await fetch(vrmUrl);
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);

                loader.load(url, (gltf) => {
                    model = gltf.scene;
                    model.position.set(0, -1, 0);
                    scene.add(model);
                    animate();
                });
            } catch (error) {
                console.error("Failed to load VRM:", error);
            }
        };

        const animate = () => {
            animationFrame = requestAnimationFrame(animate);
            if (model) model.rotation.y += 0.01;
            renderer.render(scene, camera);
        };

        init();

        return () => {
            cancelAnimationFrame(animationFrame);
            renderer.dispose();
        };
    }, [vrmUrl]);

    return <div ref={canvasRef} style={{ width: "200px", height: "200px" }} />;
}

export default VRMViewer;
