import React, { useState, useEffect } from "react";
import axios from "axios";
import { io } from "socket.io-client";
import VRMViewer from "./VRMViewer";

const API_URL = "http://localhost:5000";  // Backend API URL
const socket = io(API_URL);  // Connect WebSocket to backend

function AdminDashboard({ token }) {
  const [users, setUsers] = useState([]);
  const [vrms, setVrms] = useState([]);

  useEffect(() => {
    fetchUsers();
    fetchVRMs();

    // Real-time WebSocket updates
    socket.on("new_vrm_uploaded", (data) => {
      setVrms((prev) => [...prev, data]);
    });

    socket.on("user_banned", (data) => {
      setUsers((prev) => prev.map(user =>
        user.user_id === data.userId ? { ...user, is_banned: true } : user
      ));
    });

    return () => socket.disconnect();
  }, [token]);

  const fetchUsers = () => {
    axios.get(`${API_URL}/users`, { headers: { Authorization: token } })
      .then(res => setUsers(res.data))
      .catch(err => console.error("Error fetching users:", err));
  };

  const fetchVRMs = () => {
    axios.get(`${API_URL}/all-vrms`, { headers: { Authorization: token } })
      .then(res => setVrms(res.data))
      .catch(err => console.error("Error fetching VRMs:", err));
  };

  const assignAdmin = (userId) => {
    axios.put(`${API_URL}/assign-admin`, { userId }, { headers: { Authorization: token } })
      .then(() => {
        alert("User is now an admin!");
        fetchUsers();
      })
      .catch(err => alert("Error: " + err.response.data.msg));
  };

  const banUser = (userId) => {
    axios.put(`${API_URL}/ban-user`, { userId }, { headers: { Authorization: token } })
      .then(() => {
        alert("User has been banned!");
        fetchUsers();
      })
      .catch(err => alert("Error: " + err.response.data.msg));
  };

  const unbanUser = (userId) => {
    axios.put(`${API_URL}/unban-user`, { userId }, { headers: { Authorization: token } })
      .then(() => {
        alert("User has been unbanned!");
        fetchUsers();
      })
      .catch(err => alert("Error: " + err.response.data.msg));
  };

  const deleteVrm = (userId, filename) => {
    axios.delete(`${API_URL}/delete-vrm/${userId}`, { headers: { Authorization: token } })
      .then(() => {
        alert("VRM file deleted!");
        setVrms(vrms.filter(vrm => vrm.filename !== filename));
      })
      .catch(err => alert("Error: " + err.response.data.msg));
  };

  return (
    <div>
      <h2>Admin Dashboard (Real-Time)</h2>

      {/* Users Table */}
      <h3>Users</h3>
      <table>
        <thead>
          <tr>
            <th>Email</th>
            <th>User ID</th>
            <th>Admin</th>
            <th>Banned</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.user_id}>
              <td>{user.email}</td>
              <td>{user.user_id}</td>
              <td>{user.is_admin ? "Yes" : "No"}</td>
              <td>{user.is_banned ? "Yes" : "No"}</td>
              <td>
                {!user.is_admin && <button onClick={() => assignAdmin(user.user_id)}>Make Admin</button>}
                {!user.is_banned ?
                  <button onClick={() => banUser(user.user_id)}>Ban</button> :
                  <button onClick={() => unbanUser(user.user_id)}>Unban</button>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* VRM Models Table */}
      <h3>VRM Models</h3>
      <table>
        <thead>
          <tr>
            <th>User ID</th>
            <th>VRM File</th>
            <th>Preview</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {vrms.map(vrm => (
            <tr key={vrm.filename}>
              <td>{vrm.user_id}</td>
              <td>{vrm.filename}</td>
              <td>
                <VRMViewer vrmUrl={`${API_URL}/get-vrm/${vrm.user_id}`} />
              </td>
              <td>
                <button onClick={() => deleteVrm(vrm.user_id, vrm.filename)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AdminDashboard;
